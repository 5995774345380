import React, { useState } from 'react'
import { graphql } from 'gatsby'

import Link from '../components/link'
import Layout from '../components/layout'
import Svg from '../components/svg'
import Reveal from '../components/reveal'

export default ({ data, location }) => {
    const posts = data.allMarkdownRemark.nodes
    const [selectedOption, setSelectedOption] = useState('project')

    return (
        <Layout location={location} title="Work">
            <section className="section section-hero">
                <Reveal className="wrap">
                    <h1 className="label">Our work</h1>
                    <div className="title">
                        Whether with our clients or all by ourselves, we’re
                        always busy building something new.
                    </div>
                </Reveal>
            </section>
            {posts.map((post, k) => {
                const from = k % 2 ? 'left' : 'right'
                const title = post.frontmatter.title || post.fields.slug
                return (
                    <article
                        key={post.fields.slug}
                        className="section section-project"
                        style={{
                            background: post.frontmatter.color,
                            color: post.frontmatter.text_color
                        }}
                        itemScope
                        itemType="http://schema.org/Article"
                    >
                        <div className="wrap">
                            <Reveal from={from} className="project-text">
                                <h2 className="project-title">
                                    <Link
                                        to={post.fields.slug}
                                        itemProp="url"
                                        style={{
                                            color: post.frontmatter.text_color,
                                            borderColor:
                                                post.frontmatter.text_color
                                        }}
                                    >
                                        <span itemProp="headline">{title}</span>
                                    </Link>
                                </h2>
                                <p>
                                    Transforming UX and brand standards for the
                                    new global e-commerce experience at
                                    ToryBurch.com.
                                </p>
                            </Reveal>
                            <Reveal from="bottom" delay="0.2">
                                <img
                                    src={post.frontmatter.image1.publicURL}
                                    width="2130"
                                    height="1506"
                                    alt={title}
                                    title={title}
                                />
                            </Reveal>
                        </div>
                    </article>
                )
            })}
        </Layout>
    )
}

export const pageQuery = graphql`
    query {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { posttype: { eq: "work" } } }
        ) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    title
                    description
                    color
                    text_color
                    image1 {
                        publicURL
                    }
                }
            }
        }
    }
`
